import React from "react";
import { toggleSettingsModal } from "../../../actions/modal";
import { logout } from "../../../actions/auth";
import { connect } from "react-redux";
import "./style.scss";

const SettingsModal = ({ toggleSettingsModal, logout }) => {
  return (
    <div className="settingsModal">
      <div className="content-middle">
        <div className="button top-16 full-width" onClick={logout}>
          Sign out
        </div>
      </div>

      <div className="content-middle">
        <div
          className="button-secondary top-16 full-width"
          onClick={toggleSettingsModal}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

export default connect(null, { toggleSettingsModal, logout })(SettingsModal);
