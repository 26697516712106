import axios from "axios";
import { GET_NEW_KITS } from "./types";

export const getNewKits = limit => async dispatch => {
  try {
    const res = await axios.get(`/api/kits/getNewKits/${limit}`);

    dispatch({
      type: GET_NEW_KITS,
      payload: res.data
    });
  } catch (err) {
    dispatch(console.log("get kits error"));
  }
};
