import React, { Fragment } from "react";
import Sound from "react-sound";
import { connect } from "react-redux";
import { setCurrentTime, setCurrentDuration } from "../../actions/player";

window.soundManager.setup({ debugMode: false });

const Player = ({
  activeTrackFileUrl,
  isPlaying,
  setCurrentTime,
  setCurrentDuration,
  seekingTime,
  updateToggle,
  isLoop
}) => {
  if (activeTrackFileUrl !== null) {
    return (
      <Fragment>
        <Sound
          url={activeTrackFileUrl}
          playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.PAUSED}
          playFromPosition={seekingTime}
          onLoading={({ duration }) => setCurrentDuration(duration)}
          onPlaying={({ position }) => setCurrentTime(position)}
          loop={isLoop ? true : false}
        />
      </Fragment>
    );
  } else {
    return null;
  }
};

const mapStateToProps = state => ({
  isPlaying: state.player.isPlaying,
  activeTrackFileUrl: state.player.activeTrackFileUrl,
  seekingTime: state.player.seekingTime,
  updateToggle: state.player.updateToggle,
  isLoop: state.player.isLoop
});

export default connect(mapStateToProps, { setCurrentTime, setCurrentDuration })(
  Player
);
