import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BrowseCard from "../../components/BrowseCard";
import chroma from "chroma-js";
import axios from "axios";

import "./style.scss";

const BrowseCarousel = ({}) => {
  const scale = chroma.scale(["#008eae", "#e0245e"]);
  const [soundCount, setSoundCount] = useState({});
  const [types, setTypes] = useState([
    { name: "808", count: "" },
    { name: "Bass", count: "" },
    { name: "Clap", count: "" },
    { name: "FX", count: "" },
    { name: "Hi Hat", count: "" },
    { name: "Kick", count: "" },
    { name: "Loop", count: "" },
    { name: "One Shot", count: "" },
    { name: "Open Hat", count: "" },
    { name: "Perc", count: "" },
    { name: "Snare", count: "" },
    { name: "Vox", count: "" }
  ]);

  async function fetchSoundCount() {
    try {
      const res = await axios.get(
        `/api/tracks/soundCount/categoryCarousel/allSounds`
      );

      await setTypes([
        { name: "808", count: res.data.bass808Count },
        { name: "Bass", count: res.data.bassCount },
        { name: "Clap", count: res.data.clapCount },
        { name: "FX", count: res.data.FXCount },
        { name: "Hi Hat", count: res.data.hiHatCount },
        { name: "Kick", count: res.data.kickCount },
        { name: "Loop", count: res.data.loopCount },
        { name: "One Shot", count: res.data.oneShotCount },
        { name: "Open Hat", count: res.data.openHatCount },
        { name: "Perc", count: res.data.percCount },
        { name: "Snare", count: res.data.snareCount },
        { name: "Vox", count: res.data.voxCount }
      ]);
    } catch (err) {
      console.log("failed sound count");
    }
  }

  useEffect(() => {
    fetchSoundCount();
  }, []);

  return (
    <div className="browseCarousel">
      <div className="section-title-container">
        <div className="section-title">Browse by category</div>
      </div>
      <div className="cardCarousel">
        {types.map((type, index) => (
          <BrowseCard
            backgroundColor={scale(index / 12).hex()}
            type={type.name}
            count={type.count}
          />
        ))}
      </div>
    </div>
  );
};

export default connect()(BrowseCarousel);
