import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DownloadsTrack from "../../components/DownloadsTrack";
import axios from "axios";

import "./style.scss";

const FavouritesPage = ({ user }) => {
  const [favourites, setFavourites] = useState();

  async function fetchFavourites() {
    try {
      const token = localStorage.token;

      const config = {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json"
        }
      };

      const res = await axios.get("/api/users/getFavourites", config);
      setFavourites(res.data);
    } catch (err) {
      console.log("get kits error");
    }
  }

  useEffect(() => {
    fetchFavourites();
  }, []);

  return (
    <div className="favourites-page">
      <div className="section-title">Your favourites</div>
      <div className="download-list">
        {favourites !== undefined
          ? favourites.map(track =>
              user && track !== null ? (
                <DownloadsTrack track={track} userId={user._id} />
              ) : null
            )
          : null}

        {favourites !== undefined ? (
          favourites.length === 0 ? (
            <div className="empty-state-container">
              You don't have any favourites yet.
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(FavouritesPage);
