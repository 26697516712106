import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import FAQFooter from "../../components/FAQFooter";
import MyStoreCheckout from "../../components/stripeCheckout/MyStoreCheckout";

export default function SubscriptionPage() {
  return (
    <div className="subscriptionPage">
      <div className="content">
        <div className="top-nav-bar">
          <Link to="/dashboard">
            <div className="back-button">
              <div className="back-icon">
                <ion-icon name="ios-arrow-round-back"></ion-icon>
              </div>
              Back home
            </div>
          </Link>
          <Link to="/dashboard">
            <div className="top-logo">rDrumkits</div>
          </Link>
        </div>
        <div className="header">
          <div className="info">
            <div className="title">Get Premium.</div>
            <div className="subtitle">
              Best value and quality of sounds and loops.
            </div>
          </div>
        </div>

        <div className="subscription-content">
          <div className="info-card">
            <div className="card-label">Premium Benefits</div>
            <div className="benefits">
              <div className="benefit-item-dark">
                <div className="benefit-icon">
                  <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                </div>
                Preview unlimited sounds
              </div>
              <div className="benefit-item-dark">
                <div className="benefit-icon">
                  <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                </div>
                Unlimited free sound and loop downloads
              </div>

              <div className="benefit-item-dark">
                <div className="benefit-icon">
                  <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                </div>
                100 credits for premium sounds and loops
              </div>
              <div className="benefit-item-dark">
                <div className="benefit-icon">
                  <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                </div>
                Access to private producer community
              </div>
            </div>
          </div>

          <div className="form-box">
            <div className="form-label">Credits per month</div>
            <div className="form-option-box">
              <div className="info">
                <div className="option-radio"></div>
                <div className="option-text">100 premium credits</div>
              </div>
            </div>
            <div className="form-label">Billing plan</div>
            <div className="form-option-box">
              <div className="info">
                <div className="option-radio"></div>
                <div className="option-text">Pay monthly</div>
              </div>
              <div className="option-text">$7.99/month</div>
            </div>
            <div className="form-label">Enter card details</div>

            <MyStoreCheckout />
          </div>
        </div>

        <FAQFooter />
      </div>
    </div>
  );
}
