import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getNewKits } from "../../actions/browse";
import ContentCard from "../../components/ContentCard";
import BrowseCarousel from "../../components/BrowseCarousel";
import { toggleAuthModal } from "../../actions/modal";
import { Mixpanel } from "../../utils/Mixpanel";
import { Link } from "react-router-dom";
import "./style.scss";

const HomePage = ({
  getNewKits,
  newKits,
  toggleAuthModal,
  isAuthenticated
}) => {
  useEffect(() => {
    getNewKits(18);
  }, [getNewKits]);

  useEffect(() => {
    trackPage();
  }, [getNewKits]);

  const trackPage = () => {
    Mixpanel.track("Opened home page");
  };

  return (
    <div className="home-page">
      {isAuthenticated === false ? (
        <div className="hero-banner">
          <div className="info">
            <div className="title">
              rDrumkits is a library of over 10,000 sounds.
            </div>
            <div className="subtitle">
              Create an account to browse and download sounds.
            </div>

            <div className="button" onClick={() => toggleAuthModal("register")}>
              Create account
            </div>
          </div>
        </div>
      ) : null}

      <div className="page-container">
        <BrowseCarousel />
        <div className="section-title-container">
          <div className="section-title">Browse kits</div>
          <Link to="/browsekits">
            <div className="see-all-link">See all</div>
          </Link>
        </div>
        <div className="cardCarousel">
          {newKits.map(kit =>
            kit !== null && kit.blacklisted !== true ? (
              <ContentCard type="discover" kit={kit} key={kit._id} />
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  newKits: state.browse.newKits,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { getNewKits, toggleAuthModal })(
  HomePage
);
