import React from "react";
import "./style.scss";

export default function FAQFooter() {
  return (
    <div className="faq-container">
      <div className="section-title">Frequently asked questions</div>
      <div className="faq-box-container">
        <div className="faq-column">
          <div className="faq-box">
            <div className="faq-title">How do my download credits work?</div>
            <div className="faq-text">
              As a premium member, you will receive 100 credits per month.
              Credits "rollover" -- meaning any credits you don't use this month
              will be waiting for you next month. Every sound you download uses
              one credit. You may Like and Save unlimited sounds online without
              using any credits. If you cancel your subscription, you cannot
              access any unused credits.
            </div>
          </div>
          <div className="faq-box">
            <div className="faq-title">
              Will you be adding more sounds to this platform?
            </div>
            <div className="faq-text">
              Absolutely! While our library currently holds over 3000 sample
              packs and over 1 million audio samples across all genres, this is
              only the beginning. We are constantly adding new packs to our
              library, from new labels as well as exclusive artist packs. Check
              back frequently to find the newest and freshest sounds to use in
              your tracks.
            </div>
          </div>
        </div>
        <div className="faq-column">
          <div className="faq-box">
            <div className="faq-title">
              If I cancel my account, do I lose access to all of my sounds and
              repacks?
            </div>
            <div className="faq-text">
              If you cancel your subscription, you will maintain access to
              Splice Sounds until the end of your billing cycle, at which point
              you will no longer be charged and lose access to the browser and
              your online sounds and repacks. However, you will still have
              access to the samples and loops you have downloaded and stored on
              your computer, and the license granted to you for those sounds is
              perpetual. In addition, we will save all of your online sounds and
              repacks even after you cancel, so if you decide to come back,
              everything will be just as you left it!
            </div>
          </div>
          <div className="faq-box">
            <div className="faq-title">
              If I cancel my account, do I lose access to all of my sounds and
              repacks?
            </div>
            <div className="faq-text">
              If you cancel your subscription, you will maintain access to
              Splice Sounds until the end of your billing cycle, at which point
              you will no longer be charged and lose access to the browser and
              your online sounds and repacks. However, you will still have
              access to the samples and loops you have downloaded and stored on
              your computer, and the license granted to you for those sounds is
              perpetual. In addition, we will save all of your online sounds and
              repacks even after you cancel, so if you decide to come back,
              everything will be just as you left it!
            </div>
          </div>
        </div>
        <div className="faq-column">
          <div className="faq-box">
            <div className="faq-title">
              If I cancel my account, do I lose access to all of my sounds and
              repacks?
            </div>
            <div className="faq-text">
              If you cancel your subscription, you will maintain access to
              Splice Sounds until the end of your billing cycle, at which point
              you will no longer be charged and lose access to the browser and
              your online sounds and repacks. However, you will still have
              access to the samples and loops you have downloaded and stored on
              your computer, and the license granted to you for those sounds is
              perpetual. In addition, we will save all of your online sounds and
              repacks even after you cancel, so if you decide to come back,
              everything will be just as you left it!
            </div>
          </div>
          <div className="faq-box">
            <div className="faq-title">
              If I cancel my account, do I lose access to all of my sounds and
              repacks?
            </div>
            <div className="faq-text">
              If you cancel your subscription, you will maintain access to
              Splice Sounds until the end of your billing cycle, at which point
              you will no longer be charged and lose access to the browser and
              your online sounds and repacks. However, you will still have
              access to the samples and loops you have downloaded and stored on
              your computer, and the license granted to you for those sounds is
              perpetual. In addition, we will save all of your online sounds and
              repacks even after you cancel, so if you decide to come back,
              everything will be just as you left it!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
