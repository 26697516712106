import React, { useState } from "react";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const AuthModal = authState => {
  const [currentAuthState, setCurrentAuthState] = useState(authState.authState);

  const setCurrrentAuthStateHandler = authState => {
    setCurrentAuthState(authState);
  };

  return currentAuthState === "login" ? (
    <LoginForm
      setCurrentAuthState={authState => setCurrrentAuthStateHandler(authState)}
    />
  ) : (
    <RegisterForm
      setCurrentAuthState={authState => setCurrrentAuthStateHandler(authState)}
    />
  );
};

const mapStateToProps = state => ({
  authState: state.modal.authState
});

export default connect(mapStateToProps)(AuthModal);
