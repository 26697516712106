import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toggleNewKitModal } from "../../actions/modal";
import ContentCard from "../../components/ContentCard";
import axios from "axios";

import "./style.scss";

const UploadsPage = ({ toggleNewKitModal, user, updateToggle }) => {
  const [kits, setKits] = useState();

  async function fetchKits() {
    try {
      const token = localStorage.token;

      const config = {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json"
        }
      };

      const res = await axios.get("/api/kits", config);
      setKits(res.data);
    } catch (err) {
      console.log("get kits error");
    }
  }

  useEffect(() => {
    fetchKits();
  }, [updateToggle]);

  return (
    <div className="uploads-page">
      <div className="section-title">Your uploads</div>
      <div className="button bottom-40" onClick={toggleNewKitModal}>
        Create new kit
      </div>
      <div className="cardCarousel">
        {kits !== undefined
          ? kits.map(kit =>
              user && kit !== null ? (
                <ContentCard kit={kit} username={user.username} />
              ) : null
            )
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  updateToggle: state.kit.updateToggle
});

export default connect(mapStateToProps, { toggleNewKitModal })(UploadsPage);
