import {
  CREATE_KIT,
  GET_KITS,
  GET_KIT,
  ADD_TO_KIT,
  GET_TRACKS_FROM_KIT,
  SET_ALL_TRACKS
} from "../actions/types";

const initialState = {
  kits: [],
  kit: null,
  openCreateKitView: false,
  trackId: null,
  tracks: [],
  loading: true,
  updateToggle: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_KIT:
      return {
        ...state,
        kits: [payload, ...state.kits],
        openCreateKitView: !state.openCreateKitView,
        updateToggle: !state.updateToggle
      };

    case GET_KITS:
      return {
        ...state,
        kits: payload
      };

    case GET_KIT:
      return {
        ...state,
        kit: payload
      };

    case GET_TRACKS_FROM_KIT:
      return {
        ...state,
        tracks: payload
      };

    case ADD_TO_KIT:
      return {
        ...state,
        openAddToKitView: !state.openAddToKitView
      };

    case SET_ALL_TRACKS:
      return {
        ...state,
        loading: !state.loading
      };

    default:
      return state;
  }
}
