import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Dropzone from "../../components/Dropzone";
import EditKitImage from "../../components/EditKitImage";
import EditKitName from "../../components/EditKitName";
import EditKitDescription from "../../components/EditKitDescription";
import { toggleDeleteKitModal } from "../../actions/modal";
import { setAlert } from "../../actions/alert";

import axios from "axios";
import "./style.scss";

const EditKitPage = ({ match, user, toggleDeleteKitModal, setAlert }) => {
  const [kit, setKit] = useState(null);
  const kitId = match.params.id;
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setAlert("Sharing link copied to clipboard", "success");
  }

  async function fetchKit() {
    try {
      const res = await axios.get(`/api/kits/getKit/${kitId}`);

      setKit(res.data);
    } catch (err) {
      console.log("get kit error");
    }
  }

  useEffect(() => {
    fetchKit();
  }, [match.params.id]);

  return (
    <div className="edit-kit-page">
      {kit !== null ? (
        <div className="kit-info">
          <EditKitImage kitId={kitId} imageURL={kit.imageURL} />
          <EditKitName kitName={kit.name} kitId={kitId} />

          <div className="kit-user">{user !== null ? user.username : null}</div>

          <div className="kit-buttons">
            {document.queryCommandSupported("copy") && (
              <div className="small-button" onClick={copyToClipboard}>
                Share kit
              </div>
            )}
            <textarea
              ref={textAreaRef}
              value={`rdrumkits.com/kit/${kitId}`}
              style={{
                height: "1px",
                width: "1px",
                opacity: "0",
                position: "absolute"
              }}
            />
            <div
              className="delete-button"
              onClick={() => toggleDeleteKitModal(kitId)}
            >
              <ion-icon name="ios-more"></ion-icon>
            </div>
          </div>
          <EditKitDescription kitId={kitId} kitDescription={kit.description} />
        </div>
      ) : null}

      <Dropzone kitId={kitId} />

      <div className="track-container"></div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, { toggleDeleteKitModal, setAlert })(
  EditKitPage
);
