import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../../../actions/alert";
import { register } from "../../../../actions/auth";
import { toggleAuthModal } from "../../../../actions/modal";

import { Mixpanel } from "../../../../utils/Mixpanel";

import "../style.scss";

const RegisterForm = ({
  setAlert,
  register,
  toggleAuthModal,
  setCurrentAuthState
}) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    password2: ""
  });

  const { username, email, password, password2 } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "danger");
    } else {
      register({ username, email, password });
    }
  };

  const setAnalytics = () => {
    Mixpanel.track("Opened register modal");
  };

  useEffect(() => {
    setAnalytics();
  }, []);

  return (
    <div className="authModal">
      <div className="content-top bottom-16">
        <h2 className="title">
          Create an account for free to listen and download sounds
        </h2>
        <div className="close-icon" onClick={toggleAuthModal}>
          <ion-icon name="ios-close"></ion-icon>
        </div>
      </div>
      <div className="form">
        <form onSubmit={e => onSubmit(e)}>
          <div className="bottom-16">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={e => onChange(e)}
              name="username"
              className="text-field"
            />
          </div>
          <div className="bottom-16">
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={e => onChange(e)}
              className="text-field"
            />
          </div>
          <div className="bottom-16">
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={e => onChange(e)}
              className="text-field"
            />
          </div>
          <div className="bottom-16">
            <input
              type="password"
              placeholder="Confirm Password"
              name="password2"
              value={password2}
              onChange={e => onChange(e)}
              className="text-field"
            />
          </div>
          <input
            type="submit"
            className="button bottom-40 full-width"
            value="Sign up"
          />
        </form>
        <div className="link-text">
          Already have an account?{" "}
          <div className="link" onClick={() => setCurrentAuthState("login")}>
            Log In
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { setAlert, register, toggleAuthModal })(
  RegisterForm
);
