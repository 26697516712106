import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import FAQFooter from "../../components/FAQFooter";

export default function PricingPage() {
  return (
    <div className="pricingPage">
      <div className="content">
        <div className="top-nav-bar">
          <Link to="/dashboard">
            <div className="back-button">Back home</div>
          </Link>
          <Link to="/dashboard">
            <div className="top-logo">rDrumkits</div>
          </Link>
        </div>
        <div className="header">
          <div className="info">
            <div className="title">Choose a plan.</div>
            <div className="subtitle">You can change your plan anytime.</div>
          </div>
        </div>
        <div className="pricing-card-box">
          <div className="pricing-card-dark">
            <div className="card-text">
              Sign up for free. No credit card needed.
            </div>
            <div className="card-inner">
              <div className="card-label">Free</div>
              <div className="price-info">
                <div className="price-large">$0</div>
                <div className="price-month">/mo</div>
              </div>
              <Link to="/register/free">
                <div className="button">Get started</div>
              </Link>
              <div className="benefits">
                <div className="benefit-item-dark">
                  <div className="benefit-icon">
                    <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                  </div>
                  Preview and download unlimited free sounds
                </div>
                <div className="benefit-item-dark">
                  <div className="benefit-icon-muted">
                    <ion-icon name="ios-close-circle-outline"></ion-icon>
                  </div>
                  No loop downloads
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-card">
            <div className="card-text">
              Best value and quality of sounds and loops
            </div>
            <div className="card-inner">
              <div className="card-label">Premium</div>
              <div className="price-info">
                <div className="price-large">$7.99</div>
                <div className="price-month">/mo</div>
              </div>
              <Link to="/register/premium">
                <div className="button">Select</div>
              </Link>
              <div className="benefits">
                <div className="benefit-item">
                  <div className="benefit-icon">
                    <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                  </div>
                  Preview and download unlimited free sounds and loops
                </div>

                <div className="benefit-item">
                  <div className="benefit-icon">
                    <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                  </div>
                  100 credits for premium sounds and loops
                </div>
                <div className="benefit-item">
                  <div className="benefit-icon">
                    <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
                  </div>
                  Access to private producer community
                </div>
              </div>
            </div>
          </div>
        </div>
        <FAQFooter />
      </div>
    </div>
  );
}
