import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { toggleAuthModal } from "../../actions/modal";
import { connect } from "react-redux";
import "./style.scss";

const Bottombar = ({ isAuthenticated, toggleAuthModal }) => {
  return (
    <Fragment>
      {!isAuthenticated ? (
        <Fragment>
          <div className="bottomBar">
            <div className="info">
              <div className="main-text">All the sounds you need, curated.</div>
              <div className="sub-text">
                Sign up to browse and download 10,000+ sounds and loops.
              </div>
            </div>
            <div className="buttons">
              <div
                className="button"
                onClick={() => toggleAuthModal("register")}
              >
                Sign up for free
              </div>

              <div
                className="button button-login"
                onClick={() => toggleAuthModal("login")}
              >
                Log in
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { toggleAuthModal })(Bottombar);
