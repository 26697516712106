import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { selectTrack } from "../../actions/player";
import { toggleAuthModal } from "../../actions/modal";
import { Mixpanel } from "../../utils/Mixpanel";
import { Link } from "react-router-dom";
import axios from "axios";
import "./style.scss";

const Track = ({
  track,
  selectTrack,
  userId,
  toggleAuthModal,
  activeTrackId,
  trackType
}) => {
  const [playHover, setPlayHover] = useState(false);
  const [favourited, setFavourited] = useState(
    track.favouritedUsers.includes(userId) ? true : false
  );
  const [played, setPlayed] = useState(
    track.playedUsers.includes(userId) ? true : false
  );

  const downloadHandler = async e => {
    e.stopPropagation();
    console.log("download track");
    Mixpanel.track("Track download", { name: track.name });
    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const trackId = track._id;
    const name = track.name;
    const fileURL = track.fileURL;
    const type = track.type;

    const body = JSON.stringify({ trackId, name, fileURL, type });

    await axios.post("/api/users/addDownload", body, config);
  };

  const favouriteHandler = async e => {
    e.stopPropagation();
    setFavourited(!favourited);
    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const trackId = track._id;
    const name = track.name;
    const fileURL = track.fileURL;
    const type = track.type;

    const body = JSON.stringify({ trackId, name, fileURL, type });

    await axios.post("/api/users/toggleFavourite", body, config);

    favourited
      ? Mixpanel.track("Track remove favourite", { name: name })
      : Mixpanel.track("Track add favourite", { name: name });
  };

  const toggleAuthModalHandler = e => {
    e.stopPropagation();
    toggleAuthModal("register");
  };

  const selectTrackHandler = (fileURL, name, trackId) => {
    const isLoop = track.type === "Loop" ? true : false;

    selectTrack(fileURL, name, trackId, isLoop);

    if (userId !== "noauth" && played === false) {
      setPlayed(true);
      const token = localStorage.token;

      const config = {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json"
        }
      };
      const body = JSON.stringify({ trackId });

      axios.post("/api/users/addPlayedTrack", body, config);
    }
  };

  return (
    <div
      className="track"
      onMouseEnter={() => setPlayHover(true)}
      onMouseLeave={() => setPlayHover(false)}
      onClick={() => selectTrackHandler(track.mp3URL, track.name, track._id)}
    >
      <div className="left">
        {playHover === true ? (
          <div className="icon-box-active">
            <ion-icon name="ios-play"></ion-icon>
          </div>
        ) : userId !== "noauth" ? (
          played ? (
            <div className="icon-box">
              <ion-icon name="ios-musical-note"></ion-icon>
            </div>
          ) : (
            <div className="icon-box-not-played">
              <ion-icon name="ios-musical-note"></ion-icon>
            </div>
          )
        ) : (
          <div className="icon-box">
            <ion-icon name="ios-musical-note"></ion-icon>
          </div>
        )}
        <div className="info">
          <div
            className={activeTrackId !== track._id ? "title" : "title-active"}
          >
            {track.name}
          </div>
          <div className="type">{track.type}</div>
        </div>
      </div>

      <div className="right">
        {playHover === true ? (
          <Fragment>
            {userId !== "noauth" ? (
              <Fragment>
                {trackType !== "kit" ? (
                  <Link
                    to={`/kit/${track.kit._id}`}
                    className="download-button"
                    onClick={e => e.stopPropagation()}
                  >
                    Go to kit
                  </Link>
                ) : null}

                <a
                  className="download-button"
                  href={track.fileURL}
                  download
                  onClick={e => downloadHandler(e)}
                >
                  <div className="download-icon">
                    <ion-icon name="ios-arrow-round-down"></ion-icon>
                  </div>
                  <div className="download-text">Download</div>
                </a>
              </Fragment>
            ) : (
              <Fragment>
                {trackType !== "kit" && track.kit ? (
                  <Link
                    to={`/kit/${track.kit._id}`}
                    className="download-button"
                    onClick={e => e.stopPropagation()}
                  >
                    Go to kit
                  </Link>
                ) : null}
                <div
                  className="download-button"
                  onClick={e => toggleAuthModalHandler(e)}
                >
                  <div className="download-icon">
                    <ion-icon name="ios-arrow-round-down"></ion-icon>
                  </div>
                  <div className="download-text">Download</div>
                </div>
              </Fragment>
            )}

            {userId !== "noauth" ? (
              <div
                className="favourite-button"
                onClick={e => favouriteHandler(e)}
              >
                {favourited ? (
                  <div className="favourite-icon-active">
                    <ion-icon name="md-heart"></ion-icon>
                  </div>
                ) : (
                  <div className="favourite-icon">
                    <ion-icon name="md-heart-empty"></ion-icon>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="favourite-button"
                onClick={e => toggleAuthModalHandler(e)}
              >
                <div className="favourite-icon">
                  <ion-icon name="md-heart-empty"></ion-icon>
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div className="stat-badge">
              <div className="download-icon">
                <ion-icon name="ios-arrow-round-down"></ion-icon>
              </div>

              {track.downloadCount || 0}
            </div>

            {userId !== "noauth" ? (
              <div
                className="favourite-button"
                onClick={e => favouriteHandler(e)}
              >
                {favourited ? (
                  <div className="favourite-icon-active">
                    <ion-icon name="md-heart"></ion-icon>
                  </div>
                ) : (
                  <div className="favourite-icon">
                    <ion-icon name="md-heart-empty"></ion-icon>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="favourite-button"
                onClick={e => toggleAuthModalHandler(e)}
              >
                <div className="favourite-icon">
                  <ion-icon name="md-heart-empty"></ion-icon>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  activeTrackId: state.player.activeTrackId
});

export default connect(mapStateToProps, {
  selectTrack,
  toggleAuthModal
})(Track);
