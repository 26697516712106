import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ContentCard from "../../components/ContentCard";
import { Mixpanel } from "../../utils/Mixpanel";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import axios from "axios";
import "./style.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const BrowseKitsPage = ({}) => {
  const [browseKitsPageData, setBrowseKitsPageData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function getBrowseKitsPage() {
    try {
      setLoading(true);
      const res = await axios.get(`/api/kits/browseKitsPage`);
      await setBrowseKitsPageData(res.data);
      setLoading(false);
    } catch (err) {
      console.log("playlist error");
    }
  }

  useEffect(() => {
    getBrowseKitsPage();
  }, []);

  useEffect(() => {
    trackPage();
  }, [getBrowseKitsPage]);

  const trackPage = () => {
    Mixpanel.track("Opened browse kits page");
  };

  return (
    <div className="browse-kit-page">
      <div className="page-container">
        {loading === true ? (
          <div className="loader-container">
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={8}
              color={"#fff"}
              loading={true}
            />
          </div>
        ) : null}
        {browseKitsPageData !== null
          ? browseKitsPageData.map(kitCarousel => (
              <div className="cardCarousel">
                <div className="section-title-container">
                  <div className="section-title">
                    {kitCarousel.carouselTitle}
                  </div>
                </div>
                {kitCarousel.content.map(kit =>
                  kit !== null && kit.blacklisted !== true ? (
                    <ContentCard type="discover" kit={kit} key={kit._id} />
                  ) : null
                )}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(BrowseKitsPage);
