// CardSection.js
import React from "react";
import { CardElement } from "react-stripe-elements";
import "./style.scss";

class CardSection extends React.Component {
  render() {
    return (
      <div className="cardElement">
        <CardElement />
      </div>
    );
  }
}

export default CardSection;
