import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { addTrack } from "../../../actions/track";
import { toggleUploadModal } from "../../../actions/modal";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import "./style.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      fileName: "",
      title: ""
    };
  }

  fileChangedHandler = e => {
    e.target.files[0] !== undefined
      ? this.setState({
          [e.target.name]: e.target.files[0],
          fileName: e.target.files[0].name
        })
      : this.setState({
          [e.target.name]: e.target.files[0],
          fileName: ""
        });
  };

  onSubmit = e => {
    e.preventDefault();
    const data = this.state;
    const { file, title } = data;
    this.props.addTrack(file, title);
    this.setState({
      file: null,
      fileName: "",
      title: ""
    });
  };

  onTextChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const { file, title, fileName } = this.state;

    return (
      <form className="uploadScreen" onSubmit={this.onSubmit}>
        <div className="content-top bottom-16">
          <h2 className="title">Upload track</h2>
          <div className="close-icon" onClick={this.props.toggleUploadModal}>
            <ion-icon name="ios-close"></ion-icon>
          </div>
        </div>

        <div className="content-middle">
          {this.props.uploading ? (
            <div className="uploadForm-loading">
              <div className="text">Uploading...</div>
              <BeatLoader
                css={override}
                sizeUnit={"px"}
                size={12}
                color={"#e0245e"}
                loading={true}
              />
            </div>
          ) : (
            <Fragment>
              <input
                type="file"
                name="file"
                id="file"
                className="inputfile"
                onChange={this.fileChangedHandler}
                accept=".mp3"
              />
              <label htmlFor="file">
                {" "}
                {fileName !== "" ? (
                  <div>
                    Track selected: <br /> {fileName}{" "}
                  </div>
                ) : (
                  "Select an MP3"
                )}
              </label>
              <input
                onKeyPress={this.onKeyPress}
                type="text"
                name="title"
                rows="20"
                cols="5"
                placeholder="Title"
                required
                value={title}
                className="text-field top-16"
                onChange={this.onTextChange}
              />
              {(file !== null) & (title !== "") ? (
                <input
                  type="submit"
                  className="button top-16 full-width"
                  value="Upload"
                />
              ) : (
                <div className="button--inactive top-16 full-width">
                  {" "}
                  Upload
                </div>
              )}
            </Fragment>
          )}
        </div>
      </form>
    );
  }
}

UploadForm.propTypes = {
  addTrack: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  uploading: state.track.uploading
});

export default connect(mapStateToProps, { addTrack, toggleUploadModal })(
  UploadForm
);
