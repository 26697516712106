import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_TOGGLE_UPDATE
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { toggleSettingsModal, toggleAuthModal } from "./modal";

import { Mixpanel } from "../utils/Mixpanel";

// Load User
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR
    });
  }
};

/**
 * Register a new user
 * adds user to Users database
 *
 * @requires {post} /api/users
 */
export const register = ({ username, email, password }) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ username, email, password });

  try {
    const res = await axios.post("/api/users", body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    });

    // Load user after registering
    dispatch(loadUser());
    Mixpanel.track("Successful sign up");
    Mixpanel.identify(email);

    Mixpanel.people.set({
      $email: email,
      $created: new Date(),
      $last_name: username
    });

    dispatch(setAlert("Successfully created account", "success"));
    dispatch(toggleAuthModal());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL
    });

    Mixpanel.track("Unsuccessful sign up");
  }
};

/**
 * Logs an existing user in
 *
 * @requires {post} /api/auth
 */
export const login = (email, password) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    });

    // Load user after login
    dispatch(loadUser());
    dispatch(setAlert("Successfully logged in", "success"));

    Mixpanel.identify(email);

    Mixpanel.track("Successful login");
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, "danger")));
    }

    Mixpanel.track("Unsuccessful login");

    dispatch({
      type: LOGIN_FAIL
    });
  }
};

/**
 * Logs user out
 */
export const logout = () => async dispatch => {
  await dispatch({ type: LOGOUT });
  dispatch(toggleSettingsModal());
  dispatch(setAlert("Successfully logged out", "success"));
  Mixpanel.track("Successful sign out");
};

/**
 * Toggle Update
 */
export const userToggleUpdate = () => async dispatch => {
  await dispatch({ type: USER_TOGGLE_UPDATE });
};
