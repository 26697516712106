import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import UploadTrack from "../UploadTrack";
import EditTrack from "../EditTrack";
import axios from "axios";
import "./style.scss";

const Dropzone = ({ kitId, updateToggle }) => {
  const [files, setFiles] = useState([]);
  const [tab, setTab] = useState("released");
  const [tracklistData, setTracklistData] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(newFile => setFiles(files => [...files, newFile]));
    setTab("unpublished");
  }, []);

  const removeFileHander = index => {
    var filesArray = [...files]; // make a separate copy of the array
    if (index !== -1) {
      filesArray.splice(index, 1);
      setFiles(filesArray);
    }
  };

  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps
  } = useDropzone({ onDrop, accept: "audio/mp3, audio/wav" });

  const { isDragActive } = useDropzone({ onDrop });

  async function fetchTracklistData() {
    try {
      const res = await axios.get(`/api/kits/getTracks/allPages/${kitId}`);

      var filteredPosts = res.data.filter(function(el) {
        return el != null;
      });

      setTracklistData(filteredPosts);
    } catch (err) {
      console.log("get tracks error");
    }
  }

  const setUnpublishedTabHandler = async () => {
    await setFiles([]);
    setTab("unpublished");
  };

  useEffect(() => {
    fetchTracklistData();
  }, [kitId, tab, updateToggle]);

  return (
    <div className="uploads-container">
      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      <div className="label-container">
        <div
          className={
            tab === "unpublished" ? "uploads-label-active" : "uploads-label"
          }
          onClick={() => setUnpublishedTabHandler()}
        >
          Unpublished
        </div>
        <div
          className={
            tab === "unpublished" ? "uploads-label" : "uploads-label-active"
          }
          onClick={() => setTab("released")}
        >
          Released
        </div>
      </div>

      <div className="track-container">
        {tab === "unpublished" && files !== null ? (
          files.length > 0 ? (
            files.map((file, index) => (
              <UploadTrack
                file={file}
                kitId={kitId}
                removeFileHander={removeFileHander}
                index={index}
              />
            ))
          ) : (
            <div className="empty-state-container">
              Currently there are no unpublished sounds. Let's upload some.
            </div>
          )
        ) : null}

        {tab === "released" && tracklistData !== null ? (
          tracklistData.length > 0 ? (
            tracklistData.map(track => (
              <EditTrack kitId={kitId} track={track} />
            ))
          ) : (
            <div className="empty-state-container">
              Currently there are no released sounds. Let's upload some.
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  kit: state.kit.kit,
  updateToggle: state.track.updateToggle
});

export default connect(mapStateToProps)(Dropzone);
