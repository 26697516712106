import { TOGGLE_FIREFOX_BANNER } from "../actions/types";

const initialState = {
  bottomBannerOpen: false,
  firefoxOpen: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_FIREFOX_BANNER:
      return {
        ...state,
        bottomBannerOpen: !state.modalOpen,
        firefoxOpen: !state.firefoxOpen
      };

    default:
      return state;
  }
};
