// ALERT
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const TOGGLE_FIREFOX_BANNER = "TOGGLE_FIREFOX_BANNER";

// AUTH / USER
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_TOGGLE_UPDATE = "USER_TOGGLE_UPDATE";

// TRACKS
export const TRACK_ERROR = "TRACK_ERROR";
export const DELETE_TRACK = "DELETE_TRACK";
export const ADD_TRACK = "ADD_TRACK";
export const SEND_TRACK = "SEND_TRACK";
export const GET_TRACK = "GET_TRACK";
export const REMOVE_TRACK_FROM_KIT = "REMOVE_TRACK_FROM_KIT";

// PLAYER
export const PAUSE = "PAUSE";
export const SELECT_TRACK = "SELECT_TRACK";
export const PLAY_FIRST = "PLAY_FIRST";
export const PLAY_NEXT = "PLAY_NEXT";
export const NO_PREVIOUS = "NO_PREVIOUS";
export const PLAY_PREVIOUS = "PLAY_PREVIOUS";
export const TOGGLE_PLAY = "TOGGLE_PLAY";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const SET_CURRENT_DURATION = "SET_CURRENT_DURATION";
export const SEEK = "SEEK";

// MODAL
export const TOGGLE_UPLOAD_MODAL = "TOGGLE_UPLOAD_MODAL";
export const TOGGLE_NEW_KIT_MODAL = "TOGGLE_NEW_KIT_MODAL";
export const TOGGLE_SETTINGS_MODAL = "TOGGLE_SETTINGS_MODAL";
export const TOGGLE_DELETE_KIT_MODAL = "TOGGLE_DELETE_KIT_MODAL";
export const TOGGLE_AUTH_MODAL = "TOGGLE_AUTH_MODAL";

// KIT
export const CREATE_KIT = "CREATE_KIT";
export const GET_KITS = "GET_KITS";
export const GET_KIT = "GET_KIT";
export const ADD_TO_KIT = "ADD_TO_KIT";
export const TOGGLE_ADD_TRACK_TO_KIT_MODAL = "TOGGLE_ADD_TRACK_TO_KIT_MODAL";
export const GET_TRACKS_FROM_KIT = "GET_TRACKS_FROM_KIT";
export const CREATE_INVITE = "CREATE_INVITE";
export const SET_ALL_TRACKS = "SET_ALL_TRACKS";
export const UPDATE_KIT_IMAGE = "UPDATE_KIT_IMAGE";

// BROWSE
export const GET_NEW_KITS = "GET_NEW_KITS";
