import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

export default function index({ type, kit, username }) {
  return type === "discover" ? (
    <Link to={`/kit/${kit._id}`} className="contentCard">
      <div
        className="image"
        style={{
          backgroundImage: `url(${kit.imageURL})`
        }}
      >
        {kit.imageURL ? null : (
          <div className="icon-container">
            <ion-icon name="ios-musical-notes"></ion-icon>
          </div>
        )}
      </div>

      <div className="info">
        <div className="title">{kit.name}</div>
        {/* <div className="user">{kit.username}</div> */}
        <div className="quantity">{kit.tracks.length} sounds</div>
      </div>
    </Link>
  ) : (
    <Link to={`/editkit/${kit._id}`} className="contentCard">
      <div
        className="image"
        style={{
          backgroundImage: `url(${kit.imageURL})`
        }}
      >
        {kit.imageURL ? null : (
          <div className="icon-container">
            <ion-icon name="ios-musical-notes"></ion-icon>
          </div>
        )}
      </div>
      <div className="info">
        <div className="title">{kit.name}</div>
        <div className="user">{username}</div>
        <div className="quantity">{kit.tracks.length} sounds</div>
      </div>
    </Link>
  );
}
