import {
  TOGGLE_UPLOAD_MODAL,
  TOGGLE_NEW_KIT_MODAL,
  TOGGLE_SETTINGS_MODAL,
  TOGGLE_ADD_TRACK_TO_KIT_MODAL,
  TOGGLE_DELETE_KIT_MODAL,
  TOGGLE_AUTH_MODAL
} from "../actions/types";

const initialState = {
  modalOpen: false,
  uploadOpen: false,
  newKitOpen: false,
  settingsOpen: false,
  addTrackToKitOpen: false,
  addToKitTrackId: null,
  deleteKitOpen: false,
  kitId: null,
  authOpen: false,
  authState: "register"
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_UPLOAD_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        uploadOpen: !state.uploadOpen,
        deleteKitOpen: false
      };

    case TOGGLE_NEW_KIT_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        newKitOpen: !state.newKitOpen,
        deleteKitOpen: false
      };

    case TOGGLE_SETTINGS_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        settingsOpen: !state.settingsOpen,
        deleteKitOpen: false
      };

    case TOGGLE_ADD_TRACK_TO_KIT_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        addTrackToKitOpen: !state.addTrackToKitOpen,
        addToKitTrackId: payload,
        deleteKitOpen: false
      };

    case TOGGLE_DELETE_KIT_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        deleteKitOpen: !state.addTrackToKitOpen,
        kitId: payload
      };

    case TOGGLE_AUTH_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        authOpen: !state.authOpen,
        authState: payload
      };

    default:
      return state;
  }
};
