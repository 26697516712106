import React, { useState } from "react";
import { setAlert } from "../../actions/alert";
import { connect } from "react-redux";
import Linkify from "react-linkify";
import axios from "axios";
import "./style.scss";

const EditKitDescription = ({ kitDescription, kitId, setAlert }) => {
  const [showEditDescriptionIcon, setShowEditDescriptionIcon] = useState(false);
  const [showEditDescriptionInput, setShowEditDescriptionInput] = useState(
    false
  );
  const [updatedDescription, setUpdatedDescription] = useState(null);
  const [newDescription, setNewDescription] = useState(kitDescription);

  const onChange = e => setNewDescription(e.target.value);

  const onSubmit = async e => {
    e.preventDefault();

    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const body = JSON.stringify({ kitId, newDescription });

    await axios.patch("/api/kits/updateDescription", body, config);

    setShowEditDescriptionIcon(false);
    setShowEditDescriptionInput(false);
    setUpdatedDescription(newDescription);

    setAlert("Description updated", "success");
  };

  const cancelDescriptionChangeHandler = () => {
    setShowEditDescriptionIcon(false);
    setShowEditDescriptionInput(false);
  };

  return (
    <div className="kitDescription-container">
      {showEditDescriptionInput !== true ? (
        <div
          className="description-container"
          onMouseEnter={() => setShowEditDescriptionIcon(true)}
          onMouseLeave={() => setShowEditDescriptionIcon(false)}
        >
          {updatedDescription !== null ? (
            updatedDescription
          ) : (
            <Linkify
              properties={{
                target: "_blank"
              }}
            >
              <div className="kit-description">
                {kitDescription ? kitDescription : "Edit description"}
              </div>
            </Linkify>
          )}
          {showEditDescriptionIcon ? (
            <div
              className="icon-box"
              onClick={() => setShowEditDescriptionInput(true)}
            >
              <ion-icon name="ios-create"></ion-icon>
            </div>
          ) : null}
        </div>
      ) : (
        <form onSubmit={e => onSubmit(e)} className="description-form">
          <textarea
            placeholder={kitDescription}
            name="email"
            value={newDescription}
            onChange={e => onChange(e)}
            className="description-text-area"
          />

          <input type="submit" className="small-button" value="Save" />
          <div
            className="remove-icon"
            onClick={() => cancelDescriptionChangeHandler()}
          >
            <ion-icon name="ios-close"></ion-icon>
          </div>
        </form>
      )}
    </div>
  );
};

export default connect(null, { setAlert })(EditKitDescription);
