import React, { useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../../../actions/alert";
import { register } from "../../../actions/auth";
import { toggleAuthModal } from "../../../actions/modal";
import PropTypes from "prop-types";
import "./style.scss";

const Register = ({
  setAlert,
  register,
  isAuthenticated,
  match,
  toggleAuthModal
}) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    password2: ""
  });

  const { username, email, password, password2 } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "danger");
    } else {
      register({ username, email, password });
    }
  };

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
      <div className="registerPage">
        <div className="top-nav-bar">
          <Link to="/pricing">
            <div className="back-button">
              <div className="back-icon">
                <ion-icon name="ios-arrow-round-back"></ion-icon>
              </div>
              Back
            </div>
          </Link>

          <Link to="/dashboard">
            <div className="top-logo">rDrumkits</div>
          </Link>
        </div>
        <div className="benefits">
          <div className="benefit-item-dark">
            <div className="benefit-icon">
              <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
            </div>
            <div className="benefit-info">
              <div className="title">Quick and free sign up</div>
              <div className="subtext">
                Enter your email address to create an account
              </div>
            </div>
          </div>
          <div className="benefit-item-dark">
            <div className="benefit-icon">
              <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
            </div>
            <div className="benefit-info">
              <div className="title">Preview unlimited sounds</div>
              <div className="subtext">
                Over 100,000 sounds to preview, curate and download
              </div>
            </div>
          </div>
          <div className="benefit-item-dark">
            <div className="benefit-icon">
              <ion-icon name="ios-checkmark-circle-outline"></ion-icon>
            </div>
            <div className="benefit-info">
              <div className="title">Get 30 download credits / day</div>
              <div className="subtext">
                Get download credits everyday that you browse sounds
              </div>
            </div>
          </div>
        </div>
        <div className="mainContent">
          <div className="registerScreen">
            <div className="form">
              <h2 className="title">
                {match.params.type === "premium"
                  ? "First let's create an account."
                  : "Create a free account."}
              </h2>
              <form onSubmit={e => onSubmit(e)}>
                <div className="bottom-16">
                  <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={e => onChange(e)}
                    name="username"
                    className="text-field"
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={e => onChange(e)}
                    className="text-field"
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={e => onChange(e)}
                    className="text-field"
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="password2"
                    value={password2}
                    onChange={e => onChange(e)}
                    className="text-field"
                  />
                </div>
                <input
                  type="submit"
                  className="button bottom-40 full-width"
                  value="Sign up"
                />
              </form>
              <div className="link-text">
                Already have an account?
                <div className="link" onClick={() => toggleAuthModal("login")}>
                  Log In
                </div>
              </div>
            </div>
            <div className="logo">rDrumkits</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {
  setAlert,
  register,
  toggleAuthModal
})(Register);
