import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import Track from "../../components/Track";
import axios from "axios";
import chroma from "chroma-js";
import ReactPaginate from "react-paginate";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import "./style.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const CategoryPage = ({ match, user, setAlert, isAuthenticated }) => {
  const [category, setCategory] = useState(null);
  const [tracklistData, setTracklistData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [soundCount, setSoundCount] = useState();
  const [loading, setLoading] = useState(false);

  const textAreaRef = useRef(null);

  const scale = chroma.scale(["#008eae", "#e0245e"]);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setAlert("Sharing link copied to clipboard", "success");
  }

  async function setTypeData() {
    const type = match.params.type;
    if (type === "808") {
      setCategory({ name: "808", count: "100", color: scale(1 / 12).hex() });
    } else if (type === "Bass") {
      setCategory({ name: "Bass", count: "100", color: scale(2 / 12).hex() });
    } else if (type === "Clap") {
      setCategory({ name: "Clap", count: "100", color: scale(3 / 12).hex() });
    } else if (type === "FX") {
      setCategory({ name: "FX", count: "100", color: scale(4 / 12).hex() });
    } else if (type === "Hi Hat") {
      setCategory({ name: "Hi Hat", count: "100", color: scale(5 / 12).hex() });
    } else if (type === "Kick") {
      setCategory({ name: "Kick", count: "100", color: scale(6 / 12).hex() });
    } else if (type === "Loop") {
      setCategory({ name: "Loop", count: "100", color: scale(7 / 12).hex() });
    } else if (type === "One Shot") {
      setCategory({
        name: "One Shot",
        count: "100",
        color: scale(8 / 12).hex()
      });
    } else if (type === "Open Hat") {
      setCategory({
        name: "Open Hat",
        count: "100",
        color: scale(9 / 12).hex()
      });
    } else if (type === "Perc") {
      setCategory({ name: "Perc", count: "100", color: scale(10 / 12).hex() });
    } else if (type === "Snare") {
      setCategory({ name: "Snare", count: "100", color: scale(11 / 12).hex() });
    } else if (type === "Vox") {
      setCategory({ name: "Vox", count: "100", color: scale(12 / 12).hex() });
    }
  }

  async function fetchTracklistData() {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/tracks/category/${match.params.type}/${currentPage}`
      );

      const soundCount = await axios.get(
        `/api/tracks/soundCount/categoryPage/${match.params.type}`
      );

      var filteredPosts = res.data.filter(function(el) {
        return el != null;
      });

      await setTracklistData(filteredPosts);
      setSoundCount(soundCount.data);
      setLoading(false);
    } catch (err) {
      console.log("playlist error");
    }
  }

  useEffect(() => {
    fetchTracklistData();
  }, [match.params.type, currentPage]);

  useEffect(() => {
    setTypeData();
  }, [match.params.type]);

  const pageChangeHandler = data => {
    const selected = data.selected + 1;
    setCurrentPage(selected);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="categoryPage">
      <div className="category-content">
        <div className="category-info">
          <div
            className="image"
            style={{
              backgroundColor: category !== null ? category.color : "#111"
            }}
          >
            {category !== null ? (
              <Fragment>
                <div className="type">{category.name}</div>
                <div className="quantity">{soundCount} sounds</div>
              </Fragment>
            ) : null}
          </div>

          {/*<div className="kit-buttons">
            {document.queryCommandSupported("copy") && (
              <div className="small-button" onClick={copyToClipboard}>
                Share
              </div>
            )}
            <textarea
              ref={textAreaRef}
              value={`rdrumkits.com/category/${match.params.type}`}
              style={{ height: "1px", width: "1px", opacity: "0" }}
            />
            </div>*/}
        </div>
        <div className="track-container">
          {loading === true ? (
            <div className="loader-container">
              <BeatLoader
                css={override}
                sizeUnit={"px"}
                size={8}
                color={"#fff"}
                loading={true}
              />
            </div>
          ) : null}

          {isAuthenticated !== false && user !== null
            ? tracklistData !== null
              ? tracklistData.map(track => (
                  <Track track={track} userId={user._id} key={track._id} />
                ))
              : null
            : tracklistData !== null
            ? tracklistData.map(track => (
                <Track track={track} userId={"noauth"} key={track._id} />
              ))
            : null}
          <div className="pagination-container">
            <ReactPaginate
              activeLinkClassName={"activeLink"}
              previousLabel={"←"}
              nextLabel={"→"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              forceSelected={currentPage}
              pageCount={soundCount / 10}
              marginPagesDisplayed={0}
              pageRangeDisplayed={5}
              onPageChange={data => pageChangeHandler(data)}
              containerClassName={"pagination"}
              pageClassName={"pagination-page"}
              pageLinkClassName={"pageLink"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              previousClassName={"previousNextLink"}
              nextClassName={"previousNextLink"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert })(CategoryPage);
