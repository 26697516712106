import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./style.scss";

const Sidebar = ({ user, isAuthenticated }) => {
  return (
    <div className="sidebar">
      <Link to="/dashboard">
        <div className="sidebar-logo">rDrumkits</div>
      </Link>

      <div className="sidebar-label">Discover</div>

      <Link to="/dashboard" className="sidebar-item">
        <div className="icon-box">
          <ion-icon name="ios-musical-note"></ion-icon>
        </div>
        <div className="title">Home</div>
      </Link>

      <Link to="/browsekits" className="sidebar-item bottom-64">
        <div className="icon-box">
          <ion-icon name="ios-musical-note"></ion-icon>
        </div>
        <div className="title">Browse kits</div>
        {/*<div className="comingSoon-badge">3K</div>*/}
      </Link>

      {user !== null && isAuthenticated === true ? (
        <Fragment>
          <div className="sidebar-label">Your library</div>

          <Link to="/uploads" className="sidebar-item">
            <div className="icon-box">
              <ion-icon name="ios-musical-note"></ion-icon>
            </div>
            <div className="title">Uploads</div>
          </Link>

          <Link to="/downloads" className="sidebar-item">
            <div className="icon-box">
              <ion-icon name="ios-musical-note"></ion-icon>
            </div>
            <div className="title">Downloads</div>
          </Link>

          <Link to="/favourites" className="sidebar-item">
            <div className="icon-box">
              <ion-icon name="ios-musical-note"></ion-icon>
            </div>
            <div className="title">Favourites</div>
          </Link>
        </Fragment>
      ) : (
        <Fragment>
          {/* <div className="sidebar-label">Get started</div>
          <Link to="/pricing" className="sidebar-item">
            <div className="icon-box">
              <ion-icon name="ios-musical-note"></ion-icon>
            </div>
            <div className="title">Pricing</div>
      </Link> */}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Sidebar);
