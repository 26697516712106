// CheckoutForm.js
import React from "react";
import { injectStripe } from "react-stripe-elements";
import axios from "axios";
import CardSection from "../CardSection";
import "./style.scss";

class CheckoutForm extends React.Component {
  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Use Elements to get a reference to the Card Element mounted somewhere
    // in your <Elements> tree. Elements will know how to find your Card Element
    // becase only one is allowed.
    // See our getElement documentation for more:
    // https://stripe.com/docs/stripe-js/reference#elements-get-element
    const cardElement = this.props.elements.getElement("card");

    // From here we cal call createPaymentMethod to create a PaymentMethod
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    this.props.stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name: "Jenny Rosen" }
      })
      .then(({ paymentMethod }) => {
        console.log("Received Stripe PaymentMethod:", paymentMethod);
      });

    // You can also use createToken to create tokens.
    // See our tokens documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    // With createToken, you will not need to pass in the reference to
    // the Element. It will be inferred automatically.
    this.props.stripe.createToken({}).then(({ token }) => {
      console.log("Received Stripe token:", token);
      const authToken = localStorage.token;

      const config = {
        headers: {
          "x-auth-token": authToken,
          "Content-Type": "application/json"
        }
      };

      const body = JSON.stringify({ token: token.id });

      axios
        .post("/api/stripe/customer/create", body, config)
        .then(res => res.json())
        .then(response => {
          console.log("response", response);
        });
    });
    // token type can optionally be inferred if there is only one Element
    // with which to create tokens
    // this.props.stripe.createToken({name: 'Jenny Rosen'});

    // You can also use createSource to create Sources.
    // See our Sources documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-source
    // With createSource, you will not need to pass in the reference to
    // the Element. It will be inferred automatically.
    this.props.stripe.createSource({
      type: "card",
      owner: {
        name: "Jenny Rosen"
      }
    });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="checkoutForm">
        <CardSection />
        <button className="subscribe-button">Start premium</button>
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);
