import React, { Fragment, useState, useEffect } from "react";
import { toggleSettingsModal } from "../../actions/modal";
import { connect } from "react-redux";
import axios from "axios";
import "./style.scss";

const Topbar = ({
  isAuthenticated,
  toggleSettingsModal,
  userToggleUpdate,
  user
}) => {
  const [premiumCreditCount, setPremiumCreditCount] = useState(null);

  async function fetchPremiumCreditCount() {
    try {
      const res = await axios.get(`/api/users/getPremiumCreditCount`);

      setPremiumCreditCount(res.data);
    } catch (err) {
      console.log("playlist error");
    }
  }

  useEffect(() => {
    fetchPremiumCreditCount();
  }, [fetchPremiumCreditCount, userToggleUpdate]);

  return (
    <Fragment>
      {isAuthenticated ? (
        <Fragment>
          <div className="topBar">
            {/* <Link to="/subscription">
              <div className="upgrade-button">
                <div className="upgrade-icon">
                  <ion-icon name="ios-flower"></ion-icon>
                </div>
                Upgrade
                <div className="credits-badge">
                  {premiumCreditCount} credits
                </div>
              </div>
      </Link> */}

            <div className="user-button" onClick={toggleSettingsModal}>
              <div className="user-icon">
                <ion-icon name="person"></ion-icon>
              </div>
              {user !== null ? user.username : null}
            </div>
          </div>
          <div className="topBar-bottom"></div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  userToggleUpdate: state.auth.toggleUpdate,
  user: state.auth.user
});

export default connect(mapStateToProps, { toggleSettingsModal })(Topbar);
