import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../../../actions/auth";
import { toggleAuthModal } from "../../../../actions/modal";
import "../style.scss";

const LoginForm = ({ login, toggleAuthModal, setCurrentAuthState }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
    toggleAuthModal();
  };

  return (
    <div className="authModal">
      <div className="content-top bottom-16">
        <h2 className="title">Log in to your account</h2>
        <div className="close-icon" onClick={toggleAuthModal}>
          <ion-icon name="ios-close"></ion-icon>
        </div>
      </div>
      <div className="form">
        <form onSubmit={e => onSubmit(e)}>
          <div className="bottom-16">
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={e => onChange(e)}
              className="text-field"
            />
          </div>
          <div className="bottom-16">
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={e => onChange(e)}
              minLength="6"
              className="text-field"
            />
          </div>
          <input
            type="submit"
            className="button bottom-40 full-width"
            value="Log in"
          />
        </form>
        <div className="link-text">
          Don't have an account?{" "}
          <div className="link" onClick={() => setCurrentAuthState("register")}>
            Sign up
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { login, toggleAuthModal })(LoginForm);
