import React, { useState } from "react";
import { toggleNewKitModal } from "../../../actions/modal";
import { connect } from "react-redux";
import { createKit } from "../../../actions/kit";
import "./style.scss";

import Select from "react-select";

const options = [
  { value: false, label: "Yes, this is a free kit" },
  { value: true, label: "No, this is a premium kit" }
];

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "#f3f3f3",
    color: "#333",
    height: "48px",
    width: "100%",
    border: "none",
    fontSize: "14px",
    boxShadow: "none",
    cursor: "pointer",
    paddingLeft: "8px"
  }),
  singleValue: styles => ({
    ...styles,
    color: "#333",
    fontSize: "15px"
  }),
  input: styles => ({
    ...styles,
    color: "#333",
    fontSize: "16px"
  }),
  placeholder: styles => ({
    ...styles,
    color: "#666",
    fontSize: "16px"
  }),
  menu: styles => ({
    ...styles,
    position: "absolute",
    backgroundColor: "#181818",
    fontSize: "14px"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    backgroundColor: "#ccc"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#999"
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "black"
        : isFocused
        ? "black"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
          ? "white"
          : "white"
        : "white",
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "black" : "#e0245e")
      }
    };
  }
};

const NewKitForm = ({ toggleNewKitModal, user, createKit }) => {
  const [formData, setFormData] = useState({
    name: "",
    premiumSelector: false,
    description: ""
  });

  const { name, premiumSelector, description } = formData;
  const { username } = user;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const setPremiumHandler = premiumSelector => {
    setFormData({ ...formData, premiumSelector: premiumSelector });
  };

  const isPremium = premiumSelector.value;

  const onSubmit = async e => {
    e.preventDefault();
    createKit(name, isPremium, username, description);
    toggleNewKitModal();
  };

  return (
    <div className="newKitModal">
      <div className="content-top bottom-16">
        <h2 className="title">Create new kit</h2>
        <div className="close-icon" onClick={toggleNewKitModal}>
          <ion-icon name="ios-close"></ion-icon>
        </div>
      </div>

      <div className="content-middle">
        <form onSubmit={e => onSubmit(e)}>
          <div className="bottom-16">
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              onChange={e => onChange(e)}
              className="text-field"
            />
            <textarea
              placeholder="Description"
              name="description"
              value={description}
              onChange={e => onChange(e)}
              className="text-area"
            />
            <Select
              options={options}
              styles={colourStyles}
              value={premiumSelector}
              onChange={premiumSelector => setPremiumHandler(premiumSelector)}
              placeholder="Is this a free kit?"
            />
          </div>

          <input type="submit" className="button full-width" value="Create" />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, { toggleNewKitModal, createKit })(
  NewKitForm
);
