import {
  TOGGLE_UPLOAD_MODAL,
  TOGGLE_NEW_KIT_MODAL,
  TOGGLE_SETTINGS_MODAL,
  TOGGLE_DELETE_KIT_MODAL,
  TOGGLE_AUTH_MODAL
} from "./types";

export const toggleUploadModal = () => {
  return {
    type: TOGGLE_UPLOAD_MODAL
  };
};

export const toggleNewKitModal = () => {
  return {
    type: TOGGLE_NEW_KIT_MODAL
  };
};

export const toggleSettingsModal = () => {
  return {
    type: TOGGLE_SETTINGS_MODAL
  };
};

export const toggleDeleteKitModal = kitId => {
  return {
    type: TOGGLE_DELETE_KIT_MODAL,
    payload: kitId
  };
};

export const toggleAuthModal = authState => {
  return {
    type: TOGGLE_AUTH_MODAL,
    payload: authState
  };
};
