import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import HomePage from "./pages/HomePage";
import KitPage from "./pages/KitPage";
import UploadsPage from "./pages/UploadsPage";
import EditKitPage from "./pages/EditKitPage";
import DownloadsPage from "./pages/DownloadsPage";
import FavouritesPage from "./pages/FavouritesPage";
import CategoryPage from "./pages/CategoryPage";
import PricingPage from "./pages/PricingPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import BrowseKitsPage from "./pages/BrowseKitsPage";

import RegisterPage from "./pages/AuthPages/RegisterPage";

import Alert from "./components/alert";
import Modal from "./components/Modal";
import Bottombar from "./components/Bottombar";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import PrivateRoute from "./components/Routing/PrivateRoute";
import Player from "./components/audio";
import AudioPlayer from "./components/AudioPlayer";

import { StripeProvider } from "react-stripe-elements";

import "./App.scss";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import ScrollToTop from "./utils/scrollToTop";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <StripeProvider apiKey="pk_test_iodoxVco0NoRSc5zoJ9cY569002vtaeezr">
        <Router>
          <ScrollToTop>
            <Fragment>
              <Player />
              <AudioPlayer />
              <Bottombar />
              <Alert />
              <Modal />
              <Sidebar />
              <Topbar />
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/register/:type" component={RegisterPage} />

                <Route exact path="/dashboard" component={HomePage} />
                <Route exact path="/kit/:id" component={KitPage} />
                <Route exact path="/category/:type" component={CategoryPage} />
                <Route exact path="/pricing" component={PricingPage} />
                <Route exact path="/browsekits" component={BrowseKitsPage} />
                <PrivateRoute
                  exact
                  path="/subscription"
                  component={SubscriptionPage}
                />
                <PrivateRoute exact path="/uploads" component={UploadsPage} />
                <PrivateRoute
                  exact
                  path="/editkit/:id"
                  component={EditKitPage}
                />
                <PrivateRoute
                  exact
                  path="/downloads"
                  component={DownloadsPage}
                />
                <PrivateRoute
                  exact
                  path="/favourites"
                  component={FavouritesPage}
                />
              </Switch>
            </Fragment>
          </ScrollToTop>
        </Router>
      </StripeProvider>
    </Provider>
  );
};

export default App;
