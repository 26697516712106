import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

export default function BrowseCard({ backgroundColor, type, count }) {
  return (
    <Link to={`/category/${type}`}>
      <div className="browseCard" style={{ backgroundColor: backgroundColor }}>
        <div className="type">
          {type}
          <ion-icon name="ios-arrow-round-forward"></ion-icon>
        </div>
        <div className="quantity">{count} sounds</div>
      </div>
    </Link>
  );
}
