import {
  PAUSE,
  SELECT_TRACK,
  PLAY_FIRST,
  PLAY_NEXT,
  NO_PREVIOUS,
  PLAY_PREVIOUS,
  TOGGLE_PLAY,
  SET_CURRENT_DURATION,
  SET_CURRENT_TIME,
  SEEK
} from "../actions/types";

const initialState = {
  activeTrackId: null,
  activeTrackTitle: null,
  activeTrackFileUrl: null,
  isPlaying: false,
  currentDuration: null,
  currentTime: null,
  seekingTime: null,
  updateToggle: false,
  isLoop: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TRACK:
      return {
        ...state,
        activeTrackFileUrl: action.trackFileUrl,
        activeTrackTitle: action.trackTitle,
        activeTrackId: action.trackId,
        isPlaying: true,
        seekingTime: 0,
        updateToggle: !state.updateToggle,
        isLoop: action.isLoop
      };

    case TOGGLE_PLAY:
      return {
        ...state,
        isPlaying: !state.isPlaying,
        seekingTime: action.time,
        currentTime: action.time
      };

    case PAUSE:
      return {
        ...state,
        isPlaying: !state.isPlaying,
        seekingTime: action.time,
        currentTime: action.time
      };

    case PLAY_NEXT:
      return {
        ...state,
        activeTrackId: state.activeTrackId + 1,
        isPlaying: true
      };

    case PLAY_PREVIOUS:
      return {
        ...state,
        activeTrackId: state.activeTrackId - 1,
        isPlaying: true
      };

    case PLAY_FIRST:
      return {
        ...state,
        activeTrackId: 0
      };

    case NO_PREVIOUS:
      return {
        ...state,
        activeTrackId: state.activeTrackId
      };

    case SET_CURRENT_DURATION:
      return {
        ...state,
        currentDuration: action.duration
      };

    case SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.time
      };

    case SEEK:
      return {
        ...state,
        seekingTime: action.time
      };

    default:
      return state;
  }
};
