import React, { useState } from "react";
import { connect } from "react-redux";
import { selectTrack } from "../../actions/player";
import axios from "axios";
import "./style.scss";

const DownloadsTrack = ({ track, selectTrack, userId }) => {
  const [playHover, setPlayHover] = useState(false);
  const [favourited, setFavourited] = useState(
    track.favouritedUsers.includes(userId) ? true : false
  );

  const favouriteHandler = async e => {
    e.stopPropagation();
    setFavourited(!favourited);
    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const trackId = track._id;
    const name = track.name;
    const fileURL = track.fileURL;
    const type = track.type;

    const body = JSON.stringify({ trackId, name, fileURL, type });

    await axios.post("/api/users/toggleFavourite", body, config);
  };

  return (
    <div
      className="downloadsTrack"
      onMouseEnter={() => setPlayHover(true)}
      onMouseLeave={() => setPlayHover(false)}
      onClick={() => selectTrack(track.fileURL, track.title)}
    >
      <div className="left">
        {playHover === true ? (
          <div className="icon-box-active">
            <ion-icon name="ios-play"></ion-icon>
          </div>
        ) : (
          <div className="icon-box">
            <ion-icon name="ios-musical-note"></ion-icon>
          </div>
        )}
        <div className="info">
          <div className="title">{track.name}</div>
          <div className="type">{track.type}</div>
        </div>
      </div>
      <div className="right">
        <a
          href={track.fileURL}
          className="options-item"
          download
          className="download-icon"
          onClick={e => e.stopPropagation()}
        >
          <ion-icon name="ios-arrow-round-down"></ion-icon>
        </a>
        <div className="favourite-icon" onClick={e => favouriteHandler(e)}>
          {favourited ? (
            <ion-icon name="md-heart"></ion-icon>
          ) : (
            <ion-icon name="md-heart-empty"></ion-icon>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  selectTrack
})(DownloadsTrack);
