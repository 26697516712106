import React, { useState } from "react";
import { setAlert } from "../../actions/alert";
import { connect } from "react-redux";
import axios from "axios";

import "./style.scss";

const EditKitImage = ({ setAlert, kitId, imageURL }) => {
  const [newImage, setNewImage] = useState({});
  const [newImagePreview, setNewImagePreview] = useState(null);
  const [displayImageUpload, setDisplayImageUpload] = useState({
    mouseOver: false,
    newImageAdded: false
  });
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const fileChangedHandler = e => {
    setNewImagePreview(URL.createObjectURL(e.target.files[0]));
    setNewImage(e.target.files[0]);

    setDisplayImageUpload({
      ...displayImageUpload,
      newImageAdded: true
    });
  };

  const removeImageHandler = () => {
    setDisplayImageUpload({
      ...displayImageUpload,
      newImageAdded: false
    });
    setNewImage(null);
    setNewImagePreview(null);
  };

  const updateKitImageHandler = async e => {
    e.preventDefault();
    setUploading(true);

    const config = {
      headers: {
        "Content-Type": "application/form-data"
      }
    };

    try {
      let formdata = new FormData();
      formdata.append("image", newImage);
      formdata.append("kitId", kitId);

      await axios.patch("/api/kits/updateImage", formdata, config);

      setAlert("Image updated", "success");
      setNewImage(null);
      setUploaded(true);
      setUploading(false);
    } catch (err) {
      console.log("cant upload image");
    }
  };

  return (
    <div
      className="kit-image"
      style={{
        backgroundImage: `url(${imageURL})`
      }}
      onMouseEnter={() =>
        setDisplayImageUpload({
          ...displayImageUpload,
          mouseOver: true
        })
      }
      onMouseLeave={() =>
        setDisplayImageUpload({
          ...displayImageUpload,
          mouseOver: false
        })
      }
    >
      {newImagePreview !== null ? (
        <div
          className="image-preview"
          style={{
            backgroundImage: `url(${newImagePreview})`
          }}
        />
      ) : null}

      <form className="image-upload" onSubmit={e => updateKitImageHandler(e)}>
        <div
          className={
            displayImageUpload.mouseOver === true &&
            displayImageUpload.newImageAdded !== true
              ? "image-upload"
              : "image-upload-hidden"
          }
        >
          <input
            type="file"
            name="file"
            id="file"
            onChange={e => fileChangedHandler(e)}
            accept="image/png, image/jpeg"
            className="inputfile"
          />
          <label htmlFor="file">Upload new image</label>
        </div>
        {newImagePreview && newImage !== null ? (
          uploading !== true ? (
            <div className="image-button-container">
              <input
                type="submit"
                className="image-upload-button"
                value="Save"
              />
              <div
                className="image-remove-button"
                onClick={() => removeImageHandler()}
              >
                <ion-icon name="ios-close"></ion-icon>
              </div>
            </div>
          ) : (
            <div className="uploading-container">Uploading...</div>
          )
        ) : null}
      </form>
    </div>
  );
};

export default connect(null, { setAlert })(EditKitImage);
