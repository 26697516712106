import React, { useState, Fragment, useEffect } from "react";
import { addTrack } from "../../actions/track";
import { connect } from "react-redux";
import EditUploadTrackName from "./EditUploadTrackName";
import "./style.scss";

import Select from "react-select";

const options = [
  { value: "808", label: "808" },
  { value: "Bass", label: "Bass" },
  { value: "Clap", label: "Clap" },
  { value: "FX", label: "FX" },
  { value: "Hi Hat", label: "Hi Hat" },
  { value: "Kick", label: "Kick" },
  { value: "Loop", label: "Loop" },
  { value: "One Shot", label: "One Shot" },
  { value: "Open Hat", label: "Open Hat" },
  { value: "Perc", label: "Perc" },
  { value: "Snare", label: "Snare" },
  { value: "Vox", label: "Vox" }
];

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "#181818",
    color: "white",
    width: "140px",
    borderColor: isFocused ? "#e0245e" : "#383838",
    "&:hover": {
      borderColor: isFocused ? "#e0245e" : "#383838"
    },
    fontSize: "14px",
    boxShadow: "none",
    cursor: "pointer"
  }),
  singleValue: styles => ({
    ...styles,
    color: "white",
    fontSize: "14px"
  }),
  input: styles => ({
    ...styles,
    color: "white",
    fontSize: "14px"
  }),
  placeholder: styles => ({
    ...styles,
    color: "white",
    fontSize: "14px"
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: "#181818",
    fontSize: "14px"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    backgroundColor: "#181818"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "white"
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "black"
        : isFocused
        ? "black"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
          ? "white"
          : "white"
        : "white",
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "black" : "#e0245e")
      }
    };
  }
};

const UploadTrack = ({ file, addTrack, kitId, index, removeFileHander }) => {
  const [trackType, setTrackType] = useState();
  const [fileName, setFileName] = useState(file.name.slice(0, -4));
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const uploadHandler = async trackType => {
    setTrackType(trackType);
    setUploading(true);
    await addTrack(file, fileName, kitId, trackType.value);
    await setUploading(false);
    setUploaded(true);
  };

  const setFileNameHandler = newName => {
    setFileName({ newName });
  };

  const checkTrackType = async () => {
    var autoTrackType;

    const lowercaseFileName = fileName.toLowerCase();

    if (lowercaseFileName.includes("808")) {
      autoTrackType = "808";
    } else if (file.size > 3000000) {
      autoTrackType = "Loop";
    } else if (lowercaseFileName.includes("bass")) {
      autoTrackType = "Bass";
    } else if (lowercaseFileName.includes("clap")) {
      autoTrackType = "Clap";
    } else if (lowercaseFileName.includes("clp")) {
      autoTrackType = "Clap";
    } else if (lowercaseFileName.includes("fx")) {
      autoTrackType = "FX";
    } else if (lowercaseFileName.includes("hit")) {
      autoTrackType = "FX";
    } else if (lowercaseFileName.includes("riser")) {
      autoTrackType = "FX";
    } else if (lowercaseFileName.includes("kick")) {
      autoTrackType = "Kick";
    } else if (lowercaseFileName.includes("loop")) {
      autoTrackType = "Loop";
    } else if (lowercaseFileName.includes("one shot")) {
      autoTrackType = "One Shot";
    } else if (lowercaseFileName.includes("open hat")) {
      autoTrackType = "Open Hat";
    } else if (lowercaseFileName.includes("hat")) {
      autoTrackType = "Hi Hat";
    } else if (lowercaseFileName.includes("cowbell")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("shaker")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("tambourine")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("triangle")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("rim")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("perc")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("congo")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("tom")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("crash")) {
      autoTrackType = "Perc";
    } else if (lowercaseFileName.includes("snare")) {
      autoTrackType = "Snare";
    } else if (lowercaseFileName.includes("sn")) {
      autoTrackType = "Snare";
    } else if (lowercaseFileName.includes("vox")) {
      autoTrackType = "Vox";
    } else if (lowercaseFileName.includes("oh")) {
      autoTrackType = "Open Hat";
    } else if (lowercaseFileName.includes("hihat")) {
      autoTrackType = "Hi Hat";
    } else if (lowercaseFileName.includes("hi hat")) {
      autoTrackType = "Hi Hat";
    } else if (lowercaseFileName.includes("hat")) {
      autoTrackType = "Hi Hat";
    } else if (lowercaseFileName.includes("sub")) {
      autoTrackType = "Bass";
    } else if (lowercaseFileName.includes("adlib")) {
      autoTrackType = "Vox";
    } else if (lowercaseFileName.includes("chant")) {
      autoTrackType = "Vox";
    } else if (lowercaseFileName.includes("guitar")) {
      autoTrackType = "One Shot";
    } else if (lowercaseFileName.includes("synth")) {
      autoTrackType = "One Shot";
    } else {
      return false;
    }

    if (autoTrackType !== null) {
      setUploading(true);
      await addTrack(file, fileName, kitId, autoTrackType);
      await setUploading(false);
      setUploaded(true);
    }
  };

  useEffect(() => {
    checkTrackType();
  }, []);

  return (
    <div className="upload-track">
      <div className="left">
        <div className="icon-box">
          <ion-icon name="ios-musical-note"></ion-icon>
        </div>
        <div className="info">
          <EditUploadTrackName
            trackName={fileName}
            setFileNameHandler={setFileNameHandler}
          />
        </div>
      </div>
      <div className="right">
        {uploaded === false && uploading === false ? (
          <Fragment>
            <Select
              options={options}
              styles={colourStyles}
              value={trackType}
              onChange={trackType => uploadHandler(trackType)}
              placeholder="Select type"
            />
            <div
              className="remove-icon"
              onClick={() => removeFileHander(index)}
            >
              <ion-icon name="ios-close"></ion-icon>
            </div>
          </Fragment>
        ) : null}
        {uploading === true ? "Uploading..." : null}
        {uploaded === true ? "Released" : null}
      </div>
    </div>
  );
};

export default connect(null, { addTrack })(UploadTrack);
