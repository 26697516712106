import React, { useState } from "react";
import { deleteTrack } from "../../actions/track";
import EditTrackName from "./EditTrackName";
import { connect } from "react-redux";
import axios from "axios";
import { setAlert } from "../../actions/alert";
import "./style.scss";

import Select from "react-select";

const options = [
  { value: "808", label: "808" },
  { value: "Bass", label: "Bass" },
  { value: "Clap", label: "Clap" },
  { value: "FX", label: "FX" },
  { value: "Hi Hat", label: "Hi Hat" },
  { value: "Kick", label: "Kick" },
  { value: "Loop", label: "Loop" },
  { value: "One Shot", label: "One Shot" },
  { value: "Open Hat", label: "Open Hat" },
  { value: "Perc", label: "Perc" },
  { value: "Snare", label: "Snare" },
  { value: "Vox", label: "Vox" }
];

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "#181818",
    color: "white",
    width: "140px",
    borderColor: isFocused ? "#e0245e" : "#383838",
    "&:hover": {
      borderColor: isFocused ? "#e0245e" : "#383838"
    },
    fontSize: "14px",
    boxShadow: "none",
    cursor: "pointer"
  }),
  singleValue: styles => ({
    ...styles,
    color: "white",
    fontSize: "14px"
  }),
  input: styles => ({
    ...styles,
    color: "white",
    fontSize: "14px"
  }),
  placeholder: styles => ({
    ...styles,
    color: "white",
    fontSize: "14px"
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: "#181818",
    fontSize: "14px"
  }),
  indicatorSeparator: styles => ({
    ...styles,
    backgroundColor: "#181818"
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "white"
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "black"
        : isFocused
        ? "black"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
          ? "white"
          : "white"
        : "white",
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "black" : "#e0245e")
      }
    };
  }
};

const EditTrack = ({ kitId, track, deleteTrack, setAlert }) => {
  const [trackType, setTrackType] = useState(track.type);

  const deleteTrackHandler = (e, kitId, trackId) => {
    e.stopPropagation();
    deleteTrack(kitId, trackId);
  };

  const editTrackTypeHandler = async trackType => {
    await setTrackType(trackType);

    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const trackId = track._id;
    const newTrackType = trackType.value;

    const body = JSON.stringify({ trackId, newTrackType });

    await axios.post("/api/tracks/updateType", body, config);

    setAlert("Type updated", "success");
  };

  return (
    <div className="upload-track">
      <div className="left">
        <div className="icon-box">
          <ion-icon name="ios-musical-note"></ion-icon>
        </div>
        <div className="info">
          <EditTrackName trackName={track.name} trackId={track._id} />
        </div>
      </div>
      <div className="right">
        <Select
          options={options}
          styles={colourStyles}
          value={trackType}
          onChange={trackType => editTrackTypeHandler(trackType)}
          placeholder={track.type}
        />
        <div
          className="remove-icon"
          onClick={e => deleteTrackHandler(e, kitId, track._id)}
        >
          <ion-icon name="ios-close"></ion-icon>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { deleteTrack, setAlert })(EditTrack);
