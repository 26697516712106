import React from "react";
import { connect } from "react-redux";
import { togglePlay } from "../../actions/player";
import Seekbar from "./Seekbar";
import "rc-slider/assets/index.css";
import moment from "moment";
import "./style.scss";

const Audiobar = ({
  activeTrackTitle,
  isPlaying,
  togglePlay,
  currentTime,
  currentDuration,
  isAuthenticated,
  isLoop
}) => {
  {
    if (activeTrackTitle !== null && isLoop === true) {
      return (
        <div
          className={
            isAuthenticated ? "audioPlayer" : "audioPlayer playerMargin"
          }
        >
          <div
            className="control-button"
            onClick={() => togglePlay(currentTime)}
          >
            {isPlaying ? (
              <ion-icon name="ios-pause"></ion-icon>
            ) : (
              <ion-icon name="ios-play"></ion-icon>
            )}
          </div>

          <div className="info-box">
            <div className="title">{activeTrackTitle}</div>

            <div className="progressBar">
              <Seekbar
                currentTime={currentTime}
                currentDuration={currentDuration}
              />
            </div>

            <div className="time-container">
              <div className="time">{moment(currentTime).format("m:ss")}</div>
              <div className="time">
                {moment(currentDuration).format("m:ss")}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
};

const mapStateToProps = state => ({
  activeTrackTitle: state.player.activeTrackTitle,
  isPlaying: state.player.isPlaying,
  currentDuration: state.player.currentDuration,
  currentTime: state.player.currentTime,
  isAuthenticated: state.auth.isAuthenticated,
  isLoop: state.player.isLoop
});

export default connect(mapStateToProps, { togglePlay })(Audiobar);
