import {
  PAUSE,
  SELECT_TRACK,
  PLAY_FIRST,
  PLAY_NEXT,
  NO_PREVIOUS,
  PLAY_PREVIOUS,
  TOGGLE_PLAY,
  SET_CURRENT_DURATION,
  SET_CURRENT_TIME,
  SEEK
} from "./types";

import { Mixpanel } from "../utils/Mixpanel";

export const selectTrack = (trackFileUrl, trackTitle, trackId, isLoop) => {
  Mixpanel.track("Track play", { name: trackTitle });

  return {
    type: SELECT_TRACK,
    trackFileUrl: trackFileUrl,
    trackTitle: trackTitle,
    trackId: trackId,
    isLoop: isLoop
  };
};

export const playNext = (playlistLength, trackIndex) => {
  return playlistLength - 1 === trackIndex
    ? {
        type: PLAY_FIRST
      }
    : {
        type: PLAY_NEXT
      };
};

export const playPrevious = trackIndex => {
  return trackIndex === 0
    ? {
        type: NO_PREVIOUS
      }
    : {
        type: PLAY_PREVIOUS
      };
};

export const togglePlay = time => {
  return {
    type: TOGGLE_PLAY,
    time: time
  };
};

export const setCurrentDuration = duration => {
  return {
    type: SET_CURRENT_DURATION,
    duration
  };
};

export const setCurrentTime = time => {
  return {
    type: SET_CURRENT_TIME,
    time
  };
};

export const seek = time => {
  return {
    type: SEEK,
    time
  };
};
