import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DownloadsTrack from "../../components/DownloadsTrack";
import axios from "axios";

import "./style.scss";

const DownloadsPage = ({ user }) => {
  const [downloads, setDownloads] = useState();

  async function fetchDownloads() {
    try {
      const token = localStorage.token;

      const config = {
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json"
        }
      };

      const res = await axios.get("/api/users/getDownloads", config);
      setDownloads(res.data);
    } catch (err) {
      console.log("get kits error");
    }
  }

  useEffect(() => {
    fetchDownloads();
  }, []);

  return (
    <div className="downloads-page">
      <div className="section-title">Your downloads</div>
      <div className="download-list">
        {downloads !== undefined
          ? downloads.map(track =>
              user && track !== null ? (
                <DownloadsTrack track={track} userId={user._id} />
              ) : null
            )
          : null}
        {downloads !== undefined ? (
          downloads.length === 0 ? (
            <div className="empty-state-container">
              You don't have any downloads yet
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(DownloadsPage);
