import { SET_ALERT, REMOVE_ALERT } from "./types";
import uuid from "uuid";

/**
 * Display an alert
 *
 * @param {*} msg Alert message
 * @param {*} alertType Alert type
 * @param {*} timeout Lifetime of alert (default 5 seconds)
 */
export const setAlert = (msg, alertType, timeout = 2000) => dispatch => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id }
  });

  setTimeout(
    () =>
      dispatch({
        type: REMOVE_ALERT,
        payload: id
      }),
    timeout
  );
};
