import axios from "axios";
import { setAlert } from "./alert";
import {
  CREATE_KIT,
  GET_KITS,
  GET_KIT,
  ADD_TO_KIT,
  GET_TRACKS_FROM_KIT,
  TOGGLE_ADD_TRACK_TO_KIT_MODAL,
  SET_ALL_TRACKS
} from "./types";

import { Mixpanel } from "../utils/Mixpanel";

// Create Kit
export const createKit = (
  name,
  isPremium,
  username,
  description
) => async dispatch => {
  const token = localStorage.token;

  const config = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "application/json"
    }
  };

  try {
    const body = JSON.stringify({ name, isPremium, username, description });
    const res = await axios.post("/api/kits", body, config);

    dispatch({
      type: CREATE_KIT,
      payload: res.data
    });

    dispatch(setAlert("Kit created", "success"));
    Mixpanel.track("Successfully created kit", {
      name: name,
      username: username
    });
  } catch (err) {
    console.log(err.message, "failed");
  }
};

// Get Kits

export const getKits = () => async dispatch => {
  try {
    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const res = await axios.get("/api/kits", config);

    dispatch({
      type: GET_KITS,
      payload: res.data
    });
  } catch (err) {
    dispatch(console.log("get kits error"));
  }
};

// Get Kit
export const getKit = id => async dispatch => {
  try {
    console.log(id, "asdas");
    const res = await axios.get(`/api/kits/getKit/${id}`);

    dispatch({
      type: GET_KIT,
      payload: res.data
    });
  } catch (err) {
    console.log("get kit error");
  }
};

// Add To Kit

export const addTrackToKit = (trackID, kitID) => async dispatch => {
  try {
    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const trackIDObject = { trackID };

    const res = await axios.post(
      `api/kits/addTrack/${kitID}`,
      trackIDObject,
      config
    );

    dispatch({
      type: ADD_TO_KIT,
      payload: res.data
    });

    dispatch({
      type: TOGGLE_ADD_TRACK_TO_KIT_MODAL
    });

    dispatch(setAlert("Added to playlist", "success"));
  } catch (err) {
    dispatch(setAlert("Track already added to playlist", "danger"));
  }
};

// Get Tracks from Kit

export const getTracksFromKit = id => async dispatch => {
  try {
    const res = await axios.get(`/api/kits/getTracks/${id}`);

    dispatch({
      type: GET_TRACKS_FROM_KIT,
      payload: res.data
    });
  } catch (err) {
    dispatch(console.log("get tracks from kit error"));
  }
};

// Set tracks array, used to re-order tracks in a kit

export const setTracks = (kitID, tracks) => async dispatch => {
  try {
    const token = localStorage.token;
    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const body = {
      tracks: tracks
    };

    await axios.post(`/api/kits/setTracks/${kitID}`, body, config);
    dispatch({
      type: SET_ALL_TRACKS
    });
  } catch (err) {
    console.log(err.message);
  }
};

// Update image for a kit

export const updateKitImage = (file, kitId) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/form-data"
    }
  };

  try {
    let formdata = new FormData();
    formdata.append("image", file);
    formdata.append("kitId", kitId);

    const res = await axios.patch("/api/kits/updateImage", formdata, config);

    Mixpanel.track("Successfully updated kit image", {
      kitId: kitId
    });

    console.log(res);
    dispatch(setAlert("Image updated", "success"));
  } catch (err) {
    console.log("cant upload image");
  }
};
