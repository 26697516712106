import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Track from "../../components/Track";
import { setAlert } from "../../actions/alert";
import axios from "axios";
import Linkify from "react-linkify";
import { Mixpanel } from "../../utils/Mixpanel";
import ReactPaginate from "react-paginate";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import "./style.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const KitPage = ({ match, user, setAlert, isAuthenticated, plays }) => {
  const [kit, setKit] = useState(null);
  const [tracklistData, setTracklistData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setAlert("Sharing link copied to clipboard", "success");
    Mixpanel.track("Share kit", { name: kit.name });
  }

  async function fetchKit() {
    try {
      const res = await axios.get(`/api/kits/getKit/${match.params.id}`);

      setKit(res.data);
      Mixpanel.track("Opened kit page", { name: kit.name });
    } catch (err) {
      console.log("get team error");
    }
  }

  async function fetchTracklistData() {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/kits/getTracks/paginated/${match.params.id}/${currentPage}`
      );

      var filteredPosts = res.data.filter(function(el) {
        return el != null;
      });

      await setTracklistData(filteredPosts);
      setLoading(false);
    } catch (err) {
      console.log("playlist error");
    }
  }

  useEffect(() => {
    fetchKit();
  }, [match.params.id, isAuthenticated]);

  useEffect(() => {
    fetchTracklistData();
  }, [match.params.id, isAuthenticated, currentPage]);

  const pageChangeHandler = data => {
    const selected = data.selected + 1;
    setCurrentPage(selected);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="kit-page">
      <div className="kit-info">
        <div
          className="kit-image"
          style={{
            backgroundImage: kit !== null ? `url(${kit.imageURL})` : null
          }}
        >
          {kit !== null ? (
            kit.imageURL ? null : (
              <div className="icon-container">
                <ion-icon name="ios-musical-notes"></ion-icon>
              </div>
            )
          ) : null}
        </div>
        <div className="kit-title">{kit !== null ? kit.name : null}</div>
        {/* <div className="kit-user">{kit !== null ? kit.username : null}</div> */}
        <div className="kit-buttons">
          {document.queryCommandSupported("copy") && (
            <div className="small-button" onClick={copyToClipboard}>
              Share kit
            </div>
          )}
          <textarea
            ref={textAreaRef}
            value={kit !== null ? `rdrumkits.com/kit/${kit._id}` : null}
            style={{ height: "1px", width: "1px", opacity: "0" }}
          />
        </div>
        {kit !== null ? (
          <Linkify
            properties={{
              target: "_blank"
            }}
          >
            <div className="description-container">{kit.description}</div>
          </Linkify>
        ) : null}
      </div>

      <div className="track-container">
        {loading === true ? (
          <div className="loader-container">
            <BeatLoader
              css={override}
              sizeUnit={"px"}
              size={8}
              color={"#fff"}
              loading={true}
            />
          </div>
        ) : isAuthenticated !== false && user !== null ? (
          tracklistData !== null ? (
            tracklistData.map(track => (
              <Track
                track={track}
                userId={user._id}
                key={track._id}
                plays={plays}
                trackType={"kit"}
              />
            ))
          ) : null
        ) : tracklistData !== null ? (
          tracklistData.map(track => (
            <Track
              track={track}
              userId={"noauth"}
              key={track._id}
              plays={plays}
              trackType={"kit"}
            />
          ))
        ) : null}

        <div className="pagination-container">
          <ReactPaginate
            activeLinkClassName={"activeLink"}
            previousLabel={"←"}
            nextLabel={"→"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={kit !== null ? kit.tracks.length / 10 : 0}
            marginPagesDisplayed={0}
            pageRangeDisplayed={5}
            onPageChange={data => pageChangeHandler(data)}
            containerClassName={"pagination"}
            pageClassName={"pagination-page"}
            pageLinkClassName={"pageLink"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            previousClassName={"previousNextLink"}
            nextClassName={"previousNextLink"}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  plays: state.player.plays
});

export default connect(mapStateToProps, { setAlert })(KitPage);
