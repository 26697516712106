/**
 * Reducer actions for tracking tracks and retrieving tracks from the backend
 */
import axios from "axios";
import { setAlert } from "./alert";
import { Mixpanel } from "../utils/Mixpanel";
import {
  TRACK_ERROR,
  DELETE_TRACK,
  ADD_TRACK,
  GET_TRACK,
  SEND_TRACK,
  GET_TRACKS_FROM_KIT,
  REMOVE_TRACK_FROM_KIT
} from "./types";

/**
 * Delete a single track using its id
 */
export const deleteTrack = (kitId, trackId) => async dispatch => {
  try {
    await axios.delete(`/api/tracks/${kitId}/${trackId}`);

    dispatch({
      type: DELETE_TRACK,
      payload: trackId
    });

    dispatch(setAlert("Sound deleted", "success"));
    Mixpanel.track("Successfully deleted sound", {
      kitId: kitId,
      trackId: trackId
    });
  } catch (err) {
    dispatch({
      type: TRACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

/**
 * Upload track to S3 and Tracks database
 */
export const addTrack = (file, name, kitId, trackType) => async dispatch => {
  const config = {
    headers: {
      "Content-Type": "application/form-data"
    }
  };

  try {
    let formdata = new FormData();
    formdata.append("track", file);
    formdata.append("name", name);
    formdata.append("kitId", kitId);
    formdata.append("type", trackType);

    dispatch({
      type: SEND_TRACK
    });

    const res = await axios.post("/api/tracks", formdata, config);

    dispatch({
      type: ADD_TRACK,
      payload: res.data
    });

    dispatch(setAlert("Sound uploaded", "success"));
    Mixpanel.track("Successfully uploaded sound", {
      kitId: kitId,
      name: name
    });
  } catch (err) {
    dispatch({
      type: TRACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

/**
 * Get a single track using its id
 */
export const getTrack = id => async dispatch => {
  try {
    const res = await axios.get(`/api/tracks/${id}`);

    dispatch({
      type: GET_TRACK,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: TRACK_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

/**
 *
 * @param {*} id
 */
export const getTracksFromKit = id => async dispatch => {
  try {
    const res = await axios.get(`/api/kits/getTracks/${id}`);

    var filteredTracks = res.data.filter(function(el) {
      return el != null;
    });

    dispatch({
      type: GET_TRACKS_FROM_KIT,
      payload: filteredTracks
    });
  } catch (err) {
    dispatch(console.log("kit err"));
  }
};

/**
 * Remove track from a kit
 */
export const removeTrackFromKit = (kitID, trackID) => async dispatch => {
  try {
    await axios.delete(`/api/kits/removeTrack/${kitID}/${trackID}`);

    dispatch({
      type: REMOVE_TRACK_FROM_KIT
    });

    dispatch(setAlert("Sound removed from kit", "success"));
  } catch (err) {
    console.log(err.message);
  }
};
