import React, { useState } from "react";
import "./style.scss";

const EditUploadTrackName = ({ trackName, setFileNameHandler }) => {
  const [showEditNameIcon, setShowEditNameIcon] = useState(false);
  const [showEditNameInput, setShowEditNameInput] = useState(false);
  const [updatedName, setUpdatedName] = useState(null);
  const [newName, setNewName] = useState(trackName);

  const onChange = e => setNewName(e.target.value);

  const onSubmitNameChange = async e => {
    e.preventDefault();

    setShowEditNameIcon(false);
    setShowEditNameInput(false);
    setUpdatedName(newName);
    setFileNameHandler(newName);
  };

  const cancelNameChangeHandler = () => {
    setShowEditNameIcon(false);
    setShowEditNameInput(false);
  };

  return (
    <div className="trackName-container">
      {showEditNameInput !== true ? (
        <div
          className="name"
          onMouseEnter={() => setShowEditNameIcon(true)}
          onMouseLeave={() => setShowEditNameIcon(false)}
        >
          {updatedName !== null ? updatedName : trackName}
          {showEditNameIcon ? (
            <div
              className="editName-icon-container"
              onClick={() => setShowEditNameInput(true)}
            >
              <ion-icon name="ios-create"></ion-icon>
            </div>
          ) : null}
        </div>
      ) : (
        <form onSubmit={e => onSubmitNameChange(e)} className="name-form">
          <input
            type="text"
            placeholder={trackName}
            name="email"
            value={newName}
            onChange={e => onChange(e)}
            className="text-field-small"
          />

          <input type="submit" className="small-button" value="Save" />
          <div
            className="remove-icon"
            onClick={() => cancelNameChangeHandler()}
          >
            <ion-icon name="ios-close"></ion-icon>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditUploadTrackName;
