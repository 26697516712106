import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import UploadModal from "./UploadModal";
import NewKitModal from "./NewKitModal";
import SettingsModal from "./SettingsModal";
import DeleteKitModal from "./DeleteKitModal";
import AuthModal from "./AuthModal";
import "./style.scss";

const customStyles = {
  content: {
    border: "none",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "320px"
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)"
  }
};

class ModalClass extends Component {
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={!this.props.modalOpen}
          style={customStyles}
          ariaHideApp={false}
        >
          <div className="modal">
            {this.props.uploadOpen ? <UploadModal /> : null}
            {this.props.newKitOpen ? <NewKitModal /> : null}
            {this.props.settingsOpen ? <SettingsModal /> : null}
            {this.props.deleteKitOpen ? <DeleteKitModal /> : null}
            {this.props.authOpen ? <AuthModal /> : null}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  modalOpen: state.modal.modalOpen,
  uploadOpen: state.modal.uploadOpen,
  newKitOpen: state.modal.newKitOpen,
  settingsOpen: state.modal.settingsOpen,
  deleteKitOpen: state.modal.deleteKitOpen,
  authOpen: state.modal.authOpen
});

export default connect(mapStateToProps)(ModalClass);
