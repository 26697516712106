import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { seek } from "../../../actions/player";
import Slider from "rc-slider";
import "./style.scss";

const Seekbar = ({ currentTime, currentDuration, seek }) => {
  const [sliderValue, setSliderValue] = useState();
  const [isSeeking, setSeeking] = useState(false);

  useEffect(() => {
    setSliderValue(currentTime);
  }, [currentTime]);

  const onAfterChangeHandler = value => {
    setSliderValue(value);
    setSeeking(false);
    seek(value);
    console.log(value);
  };

  const onBeforeChangeHandler = () => {
    setSeeking(true);
  };

  return (
    <div className="audio-slider">
      {isSeeking === true ? (
        <div className="slider-top">
          <Slider
            min={0}
            max={currentDuration}
            onAfterChange={onAfterChangeHandler}
            trackStyle={{ backgroundColor: "#e0245e" }}
            railStyle={{ backgroundColor: "#404040" }}
            handleStyle={{
              borderColor: "#fff",
              backgroundColor: "#fff"
            }}
          />
        </div>
      ) : (
        <div className="slider-bottom">
          <Slider
            min={0}
            max={currentDuration}
            value={sliderValue}
            onBeforeChange={onBeforeChangeHandler}
            trackStyle={{ backgroundColor: "#b3b3b3" }}
            railStyle={{ backgroundColor: "#404040" }}
            handleStyle={{
              backgroundColor: "fff",
              borderColor: "#fff"
            }}
          />
        </div>
      )}
    </div>
  );
};

export default connect(null, { seek })(Seekbar);
