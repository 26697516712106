import React, { useState } from "react";
import { setAlert } from "../../../actions/alert";
import { connect } from "react-redux";
import axios from "axios";
import "./style.scss";

const EditTrackName = ({ trackName, trackId, setAlert }) => {
  const [showEditNameIcon, setShowEditNameIcon] = useState(false);
  const [showEditNameInput, setShowEditNameInput] = useState(false);
  const [updatedName, setUpdatedName] = useState(null);
  const [newName, setNewName] = useState(trackName);

  const onChange = e => setNewName(e.target.value);

  const onSubmit = async e => {
    e.preventDefault();

    const token = localStorage.token;

    const config = {
      headers: {
        "x-auth-token": token,
        "Content-Type": "application/json"
      }
    };

    const body = JSON.stringify({ trackId, newName });

    await axios.post("/api/tracks/updateName", body, config);

    setShowEditNameIcon(false);
    setShowEditNameInput(false);
    setUpdatedName(newName);

    setAlert("Name updated", "success");
  };

  const cancelNameChangeHandler = () => {
    setShowEditNameIcon(false);
    setShowEditNameInput(false);
  };

  return (
    <div className="trackName-container">
      {showEditNameInput !== true ? (
        <div
          className="name"
          onMouseEnter={() => setShowEditNameIcon(true)}
          onMouseLeave={() => setShowEditNameIcon(false)}
        >
          {updatedName !== null ? updatedName : trackName}
          {showEditNameIcon ? (
            <div
              className="editName-icon-container"
              onClick={() => setShowEditNameInput(true)}
            >
              <ion-icon name="ios-create"></ion-icon>
            </div>
          ) : null}
        </div>
      ) : (
        <form onSubmit={e => onSubmit(e)} className="name-form">
          <input
            type="text"
            placeholder={trackName}
            name="email"
            value={newName}
            onChange={e => onChange(e)}
            className="text-field-small"
          />

          <input type="submit" className="small-button" value="Save" />
          <div
            className="remove-icon"
            onClick={() => cancelNameChangeHandler()}
          >
            <ion-icon name="ios-close"></ion-icon>
          </div>
        </form>
      )}
    </div>
  );
};

export default connect(null, { setAlert })(EditTrackName);
