import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import track from "./track";
import player from "./player";
import modal from "./modal";
import kit from "./kit";
import browse from "./browse";
import banner from "./banner";

export default combineReducers({
  alert,
  auth,
  track,
  player,
  modal,
  kit,
  browse,
  banner
});
