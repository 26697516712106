import React, { useState } from "react";
import { toggleDeleteKitModal } from "../../../actions/modal";
import { setAlert } from "../../../actions/alert";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "./style.scss";

const DeleteKitModal = ({ toggleDeleteKitModal, kitId, setAlert }) => {
  const [kitDeleted, setKitDeleted] = useState(false);

  const deleteKitHandler = async () => {
    await axios.delete(`/api/kits/${kitId}`);
    setKitDeleted(true);
    toggleDeleteKitModal();
    setAlert("Kit deleted", "success");
  };

  return (
    <div className="deleteKitModal">
      <div className="content-middle">
        <div
          className="button top-16 full-width"
          onClick={() => deleteKitHandler()}
        >
          Delete this kit. You can't undo this action.
        </div>
      </div>

      <div className="content-middle">
        <div
          className="button-secondary top-16 full-width"
          onClick={toggleDeleteKitModal}
        >
          Cancel
        </div>
      </div>

      {kitDeleted ? <Redirect to="/uploads" push={true} /> : null}
    </div>
  );
};

const mapStateToProps = state => ({
  kitId: state.modal.kitId
});

export default connect(mapStateToProps, { toggleDeleteKitModal, setAlert })(
  DeleteKitModal
);
